<template>
  <div>
    <NavBar name="巡检设置"></NavBar>
    <div class="datapreview">
      <el-form :inline="true" class="margintop30">
        <el-form-item>
          <myInput @clear="getList" @keydown="$keyDownSearch($event, getList)" v-model="form.inspection_id"
            placeholder="请输入点位号">
            <template #pre>点位号:</template>
          </myInput>
          <!-- <el-input v-model="form.inspection_id" clearable placeholder="请输入点位号"></el-input> -->
        </el-form-item>

        <el-form-item>
          <MyButton title="搜索" @click="getList">
            <template slot="preImage">
              <img src="@/assets/img/icon/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="847251" title="新增点位" @click="setShow(true)">
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="847254" title="批量导出" @click="batchExport">
            <template slot="preImage">
              <img src="@/unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="pub-table">
        <el-table :data="tableData" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }" v-loading="loading">
          <el-table-column label="点位号" prop="inspection_sort"></el-table-column>
          <el-table-column label="点位二维码" prop="type_name">
            <template slot-scope="{ row }">
              <div class="codeImg">
                <div :id="`qrcode${row.inspection_sort}`">
                  {{
                    qrcode(row.inspection_sort,
                      `market_id=${row.market_id}&enterprise_number_id=${row.enterprise_number}&inspection_id=${row.inspection_id}`)
                  }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="点位位置" prop="remark"></el-table-column>
          <el-table-column label="操作" prop="inspection_sort">
            <template #default="{ row }">
              <pop :accessId="847253" style="margin: 0 16px" tips="编辑" @myclick="setShow(false, row)">
                <img class="icon" src="@/assets/img/icon/edit.png" alt />
              </pop>
              <pop :accessId="847254" tips="导出" @myclick="codeExport(row.inspection_sort)">
                <img class="icon" src="@/assets/img/icon/daochu.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
        <div class="apiPage">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10"
            :current-page="form.pageNum" @current-change="getList"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="visible" :title="isAdd ? '新增点位' : '编辑'" @close="diaclose" :close-on-click-modal="false">
      <el-form label-width="100px" ref="form" :model="setform" label-position="right">
        <el-form-item label="点位号">
          <el-input class="public-input" v-model="setform.inspection_sort" disabled placeholder="输入点位号"></el-input>
        </el-form-item>
        <el-form-item label="位置">
          <el-input class="public-input" v-model="setform.remark" placeholder></el-input>
        </el-form-item>
        <el-form-item>
          <span>说明：点位号默认从“1”开始，每次新增接续上一个数字</span>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton @click="visible = false">取消</MyButton>
        <MyButton type="primary" :disabled="!setform.remark" @click="inspectionSetting" left>确定</MyButton>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data () {
    return {
      loading: false,
      isAdd: true,
      tableData: [],
      page: 1,
      visible: false,
      edvisible: false,
      form: {
        pageNum: 1,
        pageSize: 10,
        market_name: "",
        market_contacts: "",
        inspection_id: "",
      },

      total: 0,
      setform: {
        remark: "",
        inspection_id: "",
        inspection_sort: "",
      },

      account: "",
    };
  },
  created () {
    this.getList();
  },
  methods: {
    inspectionSetting () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let url = this.isAdd
            ? "/inspection_setting/add"
            : "/inspection_setting/edit";
          this.$request.HttpPost(url, this.setform).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.visible = false;
            this.getList();
            console.log(res);
          });
        }
      });
    },
    exportDataEvent () {
      this.$refs.xTable1.exportData({
        type: "csv",
        filename: `点位${new Date().toLocaleString()}`,
      });
    },
    getList (e = 1) {
      this.loading = true;
      this.form.pageNum = e;
      this.tableData = [];
      this.$request
        .HttpPost("/inspection_setting/list", this.form)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.tableData = res.data.list || [];
            this.total = res.data.total;
            this.tableData.forEach((item) => {
              if (item.values) {
                item.values = JSON.parse(item.values);
              }
            });
            // this.total = res.data.total;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setShow (type, item = {}) {
      this.isAdd = type;
      if (type) {
        for (let i in this.setform) {
          this.setform[i] = "";
        }
        if (this.tableData.length > 0) {
          let maxId = this.tableData.reduce((max, item) => {
            max = max > item.inspection_sort ? max : item.inspection_sort;
            return max;
          }, 0);
          this.setform.inspection_sort = maxId + 1;
        } else {
          this.setform.inspection_sort = 1;
        }
      } else {
        for (let i in this.setform) {
          console.log(item);
          this.setform[i] = item[i];
        }
      }
      // this.
      this.visible = true;
    },
    // 生成二维码
    qrcode (refs, text) {
      this.$nextTick(() => {
        if (document.getElementById(`qrcode${refs}`)) {
          document.getElementById(`qrcode${refs}`).innerHTML = "";
          new QRCode(`qrcode${refs}`, {
            text,
            width: 200,
            height: 220,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });

        }
      });
      this.drawText(refs, text);
    },
    // 二维码添加文字
    drawText (index) {
      let image = new Image();
      image.src = require("../../../assets/img/hllogo.png");
      this.$nextTick(() => {
        let canvas = document.querySelector(`#qrcode${index} canvas`);
        if (!canvas.getContext) return;
        let ctx = canvas.getContext("2d");
        this.resize(`#qrcode${index}`, 200, 250); // 重置画布大小
        ctx.fillStyle = "rgb(255,255,255)"; // 分配颜色
        ctx.fillRect(70, 75, 50, 70);
        image.onload = () => {
          ctx.drawImage(image, 70, 75, 50, 70);
        };
        ctx.fillStyle = "#000000"; // 分配颜色
        ctx.font = "bold 30px Arial "; // 文本大小, 字体
        ctx.fillText(index, 75, 245); // 绘制文本(text, x, y)
      });
    },
    resize (id, w, h) {
      let canvas = document.querySelector(`${id} canvas`);
      let ctx = canvas.getContext("2d");
      let nc = document.createElement("canvas");
      nc.width = canvas.width;
      nc.height = canvas.height;
      nc.getContext("2d").drawImage(canvas, 0, 0);
      canvas.width = w;
      canvas.height = h;
      ctx.drawImage(nc, 0, 0);
    },
    codeExport (index) {
      let dataUrl = document
        .querySelector(`#qrcode${index}`)
        .querySelector("img").src;
      let link = document.createElement("a");
      link.download = `点位${index}.png`;
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 批量导出
    batchExport () {
      this.tableData.forEach((item) => {
        this.codeExport(item.inspection_id);
      });
    },
    diaclose () {
      this.$refs.form.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.datapreview {
  margin-top: 20px;
}

// /deep/.el-form--inline {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
.pub-table {
  height: calc(100vh - 220px);
}

.codeImg {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
